import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          QR Code Scanner In kerala | QR Code Reader in Kerala India
        </title>
        <meta
          name="description"
          content="BPOS Provides the QR Code Scanner In kerala. Best QR Code Reader in Kerala India. QR Code Reader India. QR Code Readers Kerala."
        />
      </Helmet>
      <div className="max-w-[1024px] m-auto py-6 text-justify leading-8">
        <h2 className="text-3xl font-bold">B|POS Privacy Policy</h2>
        <p className="text-justify text-xl font-semibold my-2">Introduction</p>
        <p>
          Welcome to BPOS (the "Company," "we," "our," or "us"). We are
          committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about our policy or our
          practices with regards to your personal information, please contact us
          at <b>marketing@spidertechnosoft.com</b>.
        </p>
        <p>
          This Privacy Policy applies to all information collected through our
          website (such as [bpos.in]), and any related services, sales,
          marketing, or events (we refer to them collectively in this Privacy
          Policy as the "Services").
        </p>

        <h2 className="text-xl font-semibold mt-2">
          1. Information We Collect
        </h2>
        <p className="ml-5 my-2">
          We collect personal information that you voluntarily provide to us
          when you express an interest in obtaining information about us or our
          products and services, when you participate in activities on the
          Services, or otherwise when you contact us.
        </p>
        <p className="ml-5 my-2">
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <ul className="ml-10" style={{ listStyle: "disc" }}>
          <li>
            Personal Information Provided by You: We collect names, email
            addresses, phone numbers, and other similar contact data.
          </li>
        </ul>
        <h2 className="text-xl font-semibold mt-2">
          2. How We Use Your Information
        </h2>
        <p className="ml-5 my-2">
          We use personal information collected via our Services for a variety
          of business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations.
        </p>
        <ul className="ml-10" style={{ listStyle: "disc" }}>
          <li>To respond to inquiries and provide support.</li>
          <li>To send you marketing and promotional communications.</li>
          <li>For other business purposes.</li>
        </ul>
        <h2 className="text-xl font-semibold mt-2">
          3. Sharing Your Information
        </h2>
        <p className="ml-5 my-2">
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </p>
        <h2 className="text-xl font-semibold mt-2">4. Data Retention</h2>
        <p className="ml-5 my-2">
          We retain personal information for as long as necessary to fulfill the
          purposes for which we collected it, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>
        <h2 className="text-xl font-semibold mt-2">5. Contact Us</h2>
        <p className="ml-5 my-2">
          If you have questions or comments about this policy, you may email us
          at <b>marketing@spidertechnosoft.com</b>.
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
